import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { store } from './config';
import { CookiesProvider } from 'react-cookie';
//import './sass/tree.css';
import "./bootstrap.scss";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";


const App = lazy(() => import('./App'));

ReactDOM.render(
	<React.StrictMode>
		<CookiesProvider>
			<Provider store={store} >
				<SnackbarProvider maxSnack={2} >
					<Suspense fallback={<div className="app-loading"><div className="loading"><img src="/images/loader.svg" alt="" /></div></div>}>
						<App siteName="Clancy Garrett"/>
					</Suspense>
				</SnackbarProvider>
			</Provider>
		</CookiesProvider>
  	</React.StrictMode>,
  	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
