import { referencesConstants } from "../constants";

const initialState = {loading: false, references: []};

export const referencesReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case referencesConstants.START:
            return {
                ...state,
                loading: true
            };
        case referencesConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case referencesConstants.LIST:
            return {
                ...state,
                references: payload.list && payload.list.items ? payload.list.items : [],
                pagination: payload.list && payload.list.pagination ? payload.list.pagination : null
            };
        case referencesConstants.ADD: 
            return {
                ...state,
                references: [...state.references, payload.add],
            };
        case referencesConstants.UPDATE:
            const index = state.references.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                references: [...state.references.slice(0,index),
                    payload.update,
                    ...state.references.slice(index+1)
                ],
            };
        case referencesConstants.REMOVE:
            return {
                references: [...state.references.filter( val => val.identifier !== payload.remove )]
            };
        case referencesConstants.CLEAR:
            return {
                ...state,
                references: [],
                pagination: null
            };
        default:
            return state
    }
};