import { globalService as gs } from "./global.service";

const execute = (requestKey, method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/${requestKey}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

/* ** cms-item ** */
const item = (method = "GET", params = null, params2 = null) => {
    /* return execute('item/index', method, params, params2); */
    let url = gs.httpURL(`${gs.apiRoot}/item/${gs.identity ? 'index' : 'public-index'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const categories = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/${gs.identity ? 'categories' : 'public-categories'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const reactions = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/reactions`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const comment = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/comments`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const reply = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/reply`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const membership = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/membership`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
}

const packages = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/packages`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
}

const credits = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/credits`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
}

const stripeConnect = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/stripe`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const donation = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'stripe?key=donation' : 'public-donation'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const appointments = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/appointments`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, null));
};

const logout =  (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/profile`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

/** User */

const deactivateAccount = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/deactivate-account`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const profile = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'profile' : 'public-profile'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const changeEmail = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/change-email`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const changePassword = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/change-password`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const newsletter = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/newsletter`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const wishlist = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/wishlist`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const availability = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'availability' : 'public-availability'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const disconnect = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/disconnect-subscription`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const unavailability = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/unavailability`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const is_available = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/is-available`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const transactions = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/transactions`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, null));
};


/** Product */

const services = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/${gs.identity ? 'services' : 'index'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const favorite = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/favorite`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const search = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'search' : 'public-search'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const child = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/child`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const teachers = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/teachers`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const invitation = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/invitation`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const experience = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/experience`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const education = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/education`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const award = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/award`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const orders = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/orders`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const booking = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/booking`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const meeting = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/zoom`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const review = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/${gs.identity ? 'review' : 'public-review'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const orderComment = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user-catalog/order-comment`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const location = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'location' : 'public-location'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const references = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'references' : 'public-references'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const coupons = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/${gs.identity ? 'coupon' : 'public-coupon'}`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

export const itemService = {
    execute,
    item,
    categories,
    reactions,
    comment,
    reply,

    /* user */
    changeEmail,
    changePassword,
    deactivateAccount,
    membership,
    packages,
    credits,
    stripeConnect,
    donation,
    appointments,
    newsletter,
    profile,
    logout,
    wishlist,
    availability,
    unavailability,
    is_available,
    disconnect,

    /* Product */
    orders,
    booking,
    meeting,
    review,
    orderComment,

    services,
    favorite,
    search,
    invitation,
    child,
    teachers,
    experience,
    education,
    award,

    /** proposals */

    /** transactions */
    transactions,
    location,
    references,
    coupons,
    /** Support */
};
