
import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { userReducer  } from './user.reducer';
import { alertReducer } from './alert.reducer';
import { processReducer } from './process.reducer';
import { uploadReducer } from './upload.reducer';
import { categoriesReducer } from './categories.reducer';
import { pagesReducer } from './pages.reducer';
import { configReducer } from './config.reducer';
import { notificationReducer } from './notification.reducer';
import { countriesReducer } from './countries.reducer';
import { statesReducer } from './states.reducer';
import { favoriteReducer } from './favorite.reducer';
import { childReducer } from './child.reducer';
import { experienceReducer } from './experience.reducer';
import { educationReducer } from './education.reducer';
import { awardReducer } from './award.reducer';
import { subjectReducer } from './subject.reducer';
import { unavailabilityReducer } from './unavailability.reducer';
import { messageReducer } from './message.reducer';
import { guestCartReducer } from './guest.cart.reducer';
import { locationReducer } from './location.reducer';
import { referencesReducer } from './references.reducer';

const rootReducer = combineReducers({
    authentication: authReducer,
    process: processReducer,
    alert: alertReducer,
    upload: uploadReducer,
    config: configReducer,
    user: userReducer,
    notifications: notificationReducer,
    pages: pagesReducer,
    categories: categoriesReducer,
    countries: countriesReducer,
    states: statesReducer,
    favorites: favoriteReducer,
    experiences: experienceReducer,
    educations: educationReducer,
    awards: awardReducer,
    subjects: subjectReducer,
    childs: childReducer,
    unavailabilitys: unavailabilityReducer,
    message:messageReducer,
    guestCart: guestCartReducer,
    location: locationReducer,
    references: referencesReducer,
});

export default rootReducer;
