import { globalService as gs } from "../../services";
import { guestCartConstants } from "../constants";

let data = gs.parseItem('cart');;
const initialState = data ? {loading: false, data} : {loading: false, data: null};

export const guestCartReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case guestCartConstants.CART_PROCESS_START:
            return {
                ...state,
                loading: true
            };
        case guestCartConstants.CART_PROCESS_STOP:
            return {
                ...state,
                loading: false
            };
        case guestCartConstants.CART_LIST:
            return {
                ...state,
                data: payload.list
            };
        case guestCartConstants.CART_ADD:
            return {
                ...state,
                data: [...state.data, payload.add],
            };
        case guestCartConstants.CART_UPDATE:
            const index = state.data.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                data: [...state.data.slice(0,index),
                    payload.update,
                    ...state.data.slice(index+1)
                ],
            };
        case guestCartConstants.CART_REMOVE:
            return {
                ...state,
                data: [...state.data.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case guestCartConstants.CART_REMOVE_All:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
};