import { guestCartConstants } from "../constants";

const start = () => {
    return {type: guestCartConstants.CART_PROCESS_START, payload: {loading: true}};
};

const stop = () => {
    return {type: guestCartConstants.CART_PROCESS_STOP, payload: {loading: false}};
};

const list = (res) => {
    return {type: guestCartConstants.CART_LIST, payload: {list: res}};
};

const add = (res) => {
    return {type: guestCartConstants.CART_ADD, payload: {add: res}};
};

const update = (res) => {
    return {type: guestCartConstants.CART_UPDATE, payload: {update: res}};
};

const remove = (res) => {
    return {type: guestCartConstants.CART_REMOVE, payload: {remove: res}};
};

const removeAll = (res) => {
    return {type: guestCartConstants.CART_REMOVE_All, payload: {removeAll: res}};
};

const clear = (res) => {
    return {type: guestCartConstants.CART_CLEAR, payload: {clear: res}};
};

export const guestCartSelectors = {
    start,
    stop,
    list,
    add,
    update,
    remove,
    removeAll,
    clear
}