import firebase from "firebase"
const config = {
    apiKey: "AIzaSyCUav-sDAsIO9VL3BM1IRcjzCPpcPva6wY",
    authDomain: "educoach-5a412.firebaseapp.com",
    projectId: "educoach-5a412",
    storageBucket: "educoach-5a412.appspot.com",
    messagingSenderId: "234146722581",
    appId: "1:234146722581:web:9bd815fa988acca2872f1c",
    measurementId: "G-3XET1HD892"
};

const app = firebase.initializeApp(config);
const storage = app.storage();
const db = app.database();

export const firebaseConfig = {
    firebase,
    app,
    storage,
    db
};