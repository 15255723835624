import { experienceConstants } from "../constants";

const initialState = {loading: false, experiences: []};

export const experienceReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case experienceConstants.START:
            return {
                ...state,
                loading: true
            };
        case experienceConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case experienceConstants.EXPERIENCE:
            return {
                ...state,
                experiences: payload.list.items ? payload.list.items : [],
                pagination: payload.list.pagination ? payload.list.pagination : null
            };
        case experienceConstants.ADD: 
            return {
                ...state,
                experiences: [...state.experiences, payload.add],
            };
        case experienceConstants.UPDATE:
            const index = state.experiences.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                experiences: [...state.experiences.slice(0,index),
                    payload.update,
                    ...state.experiences.slice(index+1)
                ],
            };
        case experienceConstants.REMOVE:
            return {
                experiences: [...state.experiences.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case experienceConstants.CLEAR:
            return {
                ...state,
                experiences: [],
                pagination: null
            };
        default:
            return state
    }
};