import { locationConstants } from "../constants";

const initialState = {loading: false, data: []};

export const locationReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case locationConstants.LOCATION_PROCESS_START:
            return {
                ...state,
                loading: true
            };
        case locationConstants.LOCATION_PROCESS_STOP:
            return {
                ...state,
                loading: false
            };
        case locationConstants.LOCATION_LIST:
            return {
                ...state,
                data: payload.list
            };
        case locationConstants.LOCATION_ADD:
            return {
                ...state,
                data: [...state.data, payload.add],
            };
        case locationConstants.LOCATION_UPDATE:
            const index = state.data.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                data: [...state.data.slice(0,index),
                    payload.update,
                    ...state.data.slice(index+1)
                ],
            };
        case locationConstants.LOCATION_REMOVE:
            return {
                ...state,
                data: [...state.data.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        default:
            return state
    }
};