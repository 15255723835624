import { unavailabilityConstants } from "../constants/unavailability.constants";

const initialState = {loading: false, unavailabilitys: null};

export const unavailabilityReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case unavailabilityConstants.START:
            return {
                ...state,
                loading: true
            };
        case unavailabilityConstants.STOP:
            return {
                ...state,
                loading: false
            };
        case unavailabilityConstants.UNAVAILABILITY:
            return { 
                ...state,
                unavailabilitys: payload.list ? payload.list.items : [],
                pagination: payload.list ? payload.list.pagination : null
            };
        case unavailabilityConstants.ADD: 
            return {
                ...state,
                unavailabilitys: [...state.unavailabilitys, payload.add],
            };
        case unavailabilityConstants.UPDATE:
            const index = state.unavailabilitys.findIndex(f => parseInt(f.id) === parseInt(payload.update.id));
            return {
                ...state,
                unavailabilitys: [...state.unavailabilitys.slice(0,index),
                    payload.update,
                    ...state.unavailabilitys.slice(index+1)
                ],
            };
        case unavailabilityConstants.REMOVE:
            return {
                unavailabilitys: [...state.unavailabilitys.filter( val => parseInt(val.id) !== parseInt(payload.remove) )]
            };
        case unavailabilityConstants.CLEAR:
            return {
                ...state,
                unavailabilitys: [],
                pagination: null
            };
        default:
            return state
    }
};